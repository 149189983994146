import { TranslateService } from '@ngx-translate/core';
import deBase from './base/de.json';
import frBase from './base/fr.json';
import itBase from './base/it.json';
import deVvgPlus from './vvgplus/de.json';
import frVvgPlus from './vvgplus/fr.json';
import itVvgPlus from './vvgplus/it.json';

export function loadTranslations(service: TranslateService) {
  service.setTranslation('de-CH', deBase);
  service.setTranslation('de-CH', deVvgPlus, true);

  service.setTranslation('fr-CH', frBase);
  service.setTranslation('fr-CH', frVvgPlus, true);

  service.setTranslation('it-CH', itBase);
  service.setTranslation('it-CH', itVvgPlus, true);
}
